import {
  HStack,
  Icon,
  ListItem,
  Text,
  useMenuDescendant,
} from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { BsArrowReturnLeft } from "react-icons/bs";
import { useKeyPressEvent } from "react-use";

interface EntitySearchItemProps<T> {
  item: T;
  renderDescription: (item: T) => React.ReactNode;
  onClick: () => void;
  focusedIndex: number;
}

export function EntitySearchItem<T>({
  renderDescription,
  onClick,
  focusedIndex,
  item,
}: EntitySearchItemProps<T>) {
  const ref = useRef<any>();

  const { register, index } = useMenuDescendant();

  useEffect(() => {
    if (ref && ref.current) {
      register(ref.current);
    }
  }, [ref, register]);

  const isFocused = focusedIndex === index;

  useKeyPressEvent("Enter", () => {
    if (isFocused) {
      onClick();
    }
  });

  return (
    <ListItem
      ref={ref}
      px={3}
      py={3}
      bg={isFocused ? "blackAlpha.50" : "transparent"}
      _hover={{ bg: "blackAlpha.50" }}
      _dark={{
        bg: isFocused ? "whiteAlpha.50" : "transparent",
        _hover: {
          bg: "whiteAlpha.50",
        },
      }}
      cursor="pointer"
      onClick={onClick}
      rounded="md"
    >
      <HStack justifyContent="space-between">
        <HStack>{renderDescription(item)}</HStack>
        {isFocused && (
          <HStack spacing={1}>
            <Text fontSize="xs">Enter</Text>
            <Icon as={BsArrowReturnLeft} h={3} />
          </HStack>
        )}
      </HStack>
    </ListItem>
  );
}
