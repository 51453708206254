export class ApiError extends Error {
  errors: string[];
  statusCode: number | string;
  constructor(e: any) {
    super();
    this.errors = e.errors ?? [];
    this.statusCode = e.statusCode;
  }
}

const handleFailure = (response: Response) =>
  response.json().then(
    (error) => {
      let errors: string[] = [];

      if (Array.isArray(error.errors)) {
        errors = error.errors.map((e: any) => e.message);
      } else if (typeof error.errors === "object") {
        errors = Object.keys(error.errors).map(
          (k) => `${k}: ${error.errors[k]}`
        );
      }

      throw new ApiError({
        ...error,
        errors,
      });
    },
    () => {
      throw new ApiError({
        errors: ["Could not process request"],
      });
    }
  );

const makeRequestHeaders = (
  accessToken?: string,
  additionalHeaders?: Headers
) => {
  const headers = new Headers();

  if (accessToken) {
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
  }

  headers.append("Accept", "application/json");
  headers.append("Content-Type", "application/json");

  additionalHeaders?.forEach((value, key) => {
    headers.append(key, value);
  });

  return headers;
};

const get = async (
  url: string,
  accessToken?: string,
  additionalHeaders?: Headers
) => {
  const options = {
    method: "GET",
    headers: makeRequestHeaders(accessToken, additionalHeaders),
  };

  return fetch(url, options)
    .then((r) => {
      if (!r.ok) {
        console.log(`Error fetching URL ${url}`);
        return handleFailure(r);
      }
      return r.json().then((r) => r.data);
    })
    .catch((e) => {
      if (e instanceof ApiError) {
        throw e;
      }

      throw new ApiError({
        errors: [e.message],
      });
    });
};

const post = async (
  url: string,
  data: object,
  accessToken?: string,
  additionalHeaders?: Headers,
  returnData: Boolean = false
) => {
  const options = {
    headers: makeRequestHeaders(accessToken, additionalHeaders),
    method: "POST",
    body: JSON.stringify(data),
  };

  return fetch(url, options)
    .then((r) => {
      if (!r.ok) {
        return handleFailure(r);
      }
      if (!returnData) return r.json();
      return r.json().then((r) => r.data);
    })
    .catch((e) => {
      if (e instanceof ApiError) {
        throw e;
      }

      throw new ApiError({
        errors: [e.message],
      });
    });
};

const put = async (
  url: string,
  data: object,
  accessToken?: string,
  additionalHeaders?: Headers
) => {
  return fetch(url, {
    headers: makeRequestHeaders(accessToken, additionalHeaders),
    method: "PUT",
    body: JSON.stringify(data),
  })
    .then((r) => {
      if (!r.ok) {
        return handleFailure(r);
      }
      return r.json();
    })
    .catch((e) => {
      if (e instanceof ApiError) {
        throw e;
      }

      throw new ApiError({
        errors: [e.message],
      });
    });
};

const del = async (
  url: string,
  accessToken?: string,
  additionalHeaders?: Headers
) => {
  const options = {
    headers: makeRequestHeaders(accessToken, additionalHeaders),
    method: "DELETE",
  };

  return fetch(url, options)
    .then((r) => {
      if (!r.ok) {
        return handleFailure(r);
      }
      return r.json();
    })
    .catch((e) => {
      if (e instanceof ApiError) {
        throw e;
      }

      throw new ApiError({
        errors: [e.message],
      });
    });
};

export default { get, post, put, del };
